<template>
  <div class="page">
    <!-- 手册 -->
    <h1>小程序用户使用手册</h1>
    <div class="video">
      <video
        src="https://future.zhuneng.cn/imgForMini/static/index/popularizeVideo02.mp4"
        controls="controls"
      ></video>
    </div>
    <h2>一、开启</h2>
    <p>
      首先感谢您对我们的支持，我们定将以真挚的服务，为您提供良好的使用体验。
    </p>
    <p>
      通过雅曼小程序，社区用户可以实现互帮互助，闲置物品再利用。同时平台准备了丰厚的奖品，欢迎大家积极参加!
    </p>
    <h2>二、互帮互助，社区共建</h2>
    <p class="noIndent">1. 未来心愿</p>
    <p>
      可发表对社区的愿望期待，同时也可发表自身的愿望或者祝福，大家可以畅所欲言，发表心愿。审核通过后，用户可获得2甜蜜值。(每人每日可获得一次甜蜜值)
    </p>
    <img src="./img/futureWill1.png" alt="" />
    <p class="noIndent">2. 社区达人</p>
    <p>认证合伙人，通过后可有权力举办活动</p>
    <img src="./img/futureWill2.png" alt="" />
    <p class="noIndent">3. 参与活动</p>
    <img src="./img/futureWill3.png" alt="" />
    <h2>三、甜蜜值的获取与兑换</h2>
    <p class="noIndent">如何获取甜蜜值</p>
    <p>1. 用户注册：获得5甜蜜值，完成注册即可。</p>
    <p>
      2.
      用户完善资料：获取10甜蜜值，必须填写完整房号，并审核通过才能获得，且只能获得一次。
    </p>
    <p>3. 每日签到：签到获取1甜蜜值。</p>
    <p>
      4. 参加活动：用户报名参与活动后可以获得5甜蜜值（扫描活动签到二维码获得）。
    </p>
    <p>
      5.
      未来心愿：用户提交心愿信息经小和审核后，可获取2甜蜜值（每人每日仅第一次发布可以获得甜蜜值哦）。
    </p>
    <p>6. 社区共建：用户提交社区共建意见经小和审核后，可获取1-5甜蜜值。</p>
    <p>7. 公益捐赠：用户公益捐赠物品后，根据物品价值，可获取5-100甜蜜值。</p>
    <p>
      8.
      运动打卡：用户上传运动软件打卡照片经小和审核后，可获取2甜蜜值，每日仅可上传一次，审核失败允许重新提交，审核通过后，当天无法再发起提交。
    </p>
    <p>
      9. 申请社区达人：提交社区达人申请，运营官小和审核通过后可获取30甜蜜值。
    </p>
    <p>
      10.
      社区达人举办活动：社区达人提交举办活动申请，运营官小和审核通过并成功组建活动后，可获取10-50甜蜜值。
    </p>
    <p>11. 用户参加社区开展的志愿者活动：可获取5-10甜蜜值。</p>
    <img src="./img/futurePoints1.png" alt="" />
    <p class="noIndent">如何兑换甜蜜值</p>
    <img src="./img/futurePoints2.png" alt="" />
    <img src="./img/futurePoints3.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "manual",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.page {
  padding: 0 32px;
  .video {
    width: 100%;
    height: 400px;
    video {
      width: 100%;
      height: 100%;
    }
  }
  h1 {
    font-size: 48px;
    text-align: center;
    margin: 24px 0 48px;
  }
  h2 {
    font-size: 28px;
    font-weight: bold;
    margin: 24px 0 24px;
  }
  p {
    &.noIndent {
      text-indent: 0px;
    }
    text-indent: 2em;
    font-size: 28px;
    line-height: 44px;
    padding-bottom: 8px;
    &.storage {
      font-weight: bold;
    }
    &.blue {
      color: #007eff;
    }
  }
  img {
    width: 100%;
  }
}
</style>
